import React, {useEffect, useRef} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import cn from "classnames";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";

const Block3Chart = ({data = [], index, fillColor = '#8BD8AB', strokeColor = '#8BD8AB', visibleLabels = false, visibleBullets = false, postfix = '%', minHeight = 0, maxHeight = 102, nameField = 'value'}) => {
    let root = null;
    let container = useRef(null);

    const destroyChart = () => {
        root && root.dispose();
    }

    const initChart = (data = [],
                      index,
                      fillColor = '#8BD8AB',
                      strokeColor = '#8BD8AB',
                      visibleLabels = false,
                      visibleBullets = false,
                      postfix = '%',
                      minHeight = 0,
                      maxHeight = 102,
                      nameField = 'value') => {



        am5.ready(function () {
            root = am5.Root.new(`chart3${index}`);
            root.locale = am5locales_ru_RU;

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                layout: root.verticalLayout,
                focusable: true,
            }));

            chart.zoomOutButton.set("forceHidden", true);

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none"
            }));

            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 10,
                minorGridEnabled: true,
                strokeOpacity: 0
            });

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer
            }));

            xRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            })

            xRenderer.labels.template.setAll({
                paddingTop: 5,
                fontSize: 11,
                visible: visibleLabels,
            });

            xAxis.data.setAll(data);

            let yAxisRenderer = am5xy.AxisRendererY.new(root,
                { opposite: false }
            );

            yAxisRenderer.labels.template.setAll({
                fontSize: 11,
                visible: true,
                minPosition: 0.1,
                maxPosition: 0.9
            });

            yAxisRenderer.grid.template.setAll({
                visible: true,
                minorGridEnabled: false,
                minPosition: 0.1,
                maxPosition: 0.9

            })

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: yAxisRenderer,
            }));


            let series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: nameField,
                    categoryXField: "month",
                    stroke: strokeColor,
                    maskBullets: true,
                    fill: fillColor,
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}"
                    })
                })
            );

            series.fills.template.setAll({
                visible: true,
                opacity: 0.5
            });

            if (visibleBullets) {
                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        locationY: 1,
                        sprite: am5.Circle.new(root, {
                            radius: 3,
                            fill: fillColor,
                            stroke: strokeColor,
                        })
                    })
                });

            }
            series.data.setAll(data);

            container.current = root;
            series.appear();
            chart.appear(1000, 100);
        });
    }

    useEffect(() => {
        initChart(data.chart,
            index,
            fillColor,
            strokeColor,
            visibleLabels,
            visibleBullets,
            postfix,
            data.minPercentages,
            data.maxPercentages,
            nameField)

        return () => destroyChart();
    }, [data]);

    return (
        <>
            <div className={cn('content chart__item')}>
                <div className={cn('item item__ico')}>
                    <img src={data.icon} alt={data.title} width={30} height={30}/>
                </div>
                <div className={cn('item item__chart')} id={`chart3${index}`}></div>
            </div>
        </>
    )
}

export default Block3Chart;