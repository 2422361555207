import React, {useEffect, useRef} from "react";
import cn from "classnames";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Chart1 = ({data}) => {
    let root = null;
    let container = useRef(null);

    const destroyChart = () => {
        root && root.dispose();
    }

    const initChart = (data = [], totalHeight = 100) => {
        am5.ready(function () {
            root = am5.Root.new('chart1');
            root.locale = am5locales_ru_RU;

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                //paddingLeft: 10,
                //paddingRight: 10,
                layout: root.verticalLayout
            }));

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none"
            }));

            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 10,
                minorGridEnabled: true,
                strokeOpacity: 0.1
            });

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer
            }));

            xRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            })

            xRenderer.labels.template.setAll({
                paddingTop: 5,
                fontSize: 11,
            });

            xAxis.data.setAll(data);

            let yRenderer = am5xy.AxisRendererY.new(root, {});

            yRenderer.labels.template.setAll({
                fontSize: 11,
                visible: true,
            });


            yRenderer.grid.template.setAll({
                location: 1,
                visible: true,
            });

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
                min: 0,
                max: totalHeight*1.05,
            }));

            let paretoAxisRenderer = am5xy.AxisRendererY.new(root, {opposite: true});

            paretoAxisRenderer.labels.template.setAll({
                fontSize: 11,
                visible: false,
            });

            let paretoAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: paretoAxisRenderer,
                min: 0,
                max: 100,
                strictMinMax: true
            }));

            paretoAxisRenderer.grid.template.set("forceHidden", true);
            paretoAxis.set("numberFormat", "#'%");

            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "visits",
                categoryXField: "month",

            }));

            series.columns.template.setAll({
                maxWidth:45,
                //tooltipText: "{valueY}",
                tooltipY: 0,
                fill: "#8BD8AB",
                strokeOpacity: 0,
                cornerRadiusTL: 0,
                cornerRadiusTR: 0
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        //fill: root.interfaceColors.get("alternativeText"),
                        fontSize: 11,
                        centerY: 20,
                        centerX: am5.p50,
                        populateText: true,
                    })
                });
            });

            let paretoSeries = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: paretoAxis,
                    valueYField: "percentages",
                    categoryXField: "month",
                    stroke: "#0FAFE2",
                    maskBullets: true,
                    fill: "#0FAFE2",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}%"
                    })
                })
            );

            paretoSeries.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Label.new(root, {
                        //text: "{valueY}%",
                        fontSize: 11,
                        centerY: 25,
                        centerX: am5.p50,
                        populateText: true,

                    })
                });
            });

            paretoSeries.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Circle.new(root, {
                        radius: 3,
                        fill: "#0FAFE2",
                        stroke: "#0FAFE2",
                    })
                })
            });

            let legend = chart.children.push(am5.Legend.new(root, {
                nameField: "name",
                fillField: "color",
                strokeField: "color",
                centerX: am5.percent(50),
                x: am5.percent(50)
            }));
            legend.valueLabels.template.set("forceHidden", true);
            legend.markers.template.setAll({
                height:10,
                width:10,
            })
            legend.labels.template.setAll({
                fontSize:11,
                paddingRight:0
            })
            legend.data.setAll([{
                name: "Конверсия, %",
                color: am5.color(0x0FAFE2)
            }, {
                name: "Заявки, шт",
                color: am5.color(0x8BD8AB)
            }]);

            series.data.setAll(data);
            paretoSeries.data.setAll(data);

            container.current = root;
            series.appear();
            chart.appear(1000, 100);
        });
    }

    useEffect(() => {
        initChart(data.items, data.total)
        return () => destroyChart();
    }, [data])


    return (
        <>
            <div className={cn('chart')} style={{minHeight: "350px"}}>
                <div className={cn('chart__title')}>Кол-во заявок и конверсия в отзыв</div>
                <div className={cn('chart__content')} id={'chart1'}></div>
                <div className={cn('chart__legend')}>&nbsp;</div>
            </div>
        </>
    )
}

export default Chart1;