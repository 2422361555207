import userStore from './user';
import {filterStore, globalFilterStore} from './filter';
import uiStore from "./ui";
import applicationFormAddStore from "./ApplicationFormAdd";
import notificationStore from "./notification";
import {geoStore, geoDataStore} from "./forms/geo.store";
import tagStore from "./tag";
import geoDiffStore from "./forms/geoDifference.store";

// Сторы страниц
import reviewsStore from "./review";
import reviewFilterStore from "./reviewFilter";
import chartStore from "./chart";
import analyticsStore from './analytics';
import developersTagStore from './developersTag';
import integrationStore from "./integration";
import dashboardStore from './dashboardStore';
import qrTransitionStore from './QrTransitionStore';
import storageFilesStore from "./storageFiles";

const stores = {
    userStore,
    filterStore,
    globalFilterStore,
    uiStore,
    applicationFormAddStore,
    reviewsStore,
    reviewFilterStore,
    chartStore,
    analyticsStore,
    developersTagStore,
    notificationStore,
    integrationStore,
    dashboardStore,
    geoStore,
    geoDataStore,
    tagStore,
    qrTransitionStore,
    storageFilesStore,
    geoDiffStore
};

export default stores;
