import React, {useEffect, useState} from "react";
import './StatusBadge.scss';
import cn from "classnames";

const StatusBadge = ({children, code = 1}) => {

    return (
        <>
            <div className={cn('status', `type${code}`)}>{children}</div>
        </>
    )
}

export default StatusBadge;


