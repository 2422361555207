export default [

    {
        key: 'Status',
        href: '#Status',
        title: 'Статус',
    },
    {
        key: 'NameCompany',
        href: '#NameCompany',
        title: 'Название',
    },
/*
    {
        key: 'BrandBranch',
        href: '#BrandBranch',
        title: 'Бренд и код',
    },
*/
    {
        key: 'AddressCompany',
        href: '#AddressCompany',
        title: 'Адрес',
    },
/*
    {
        key: 'LegalInfo',
        href: '#LegalInfo',
        title: 'Юр. инфо.',
    },
*/
    {
        key: 'Category',
        href: '#Category',
        title: 'Категории',
    },

    {
        key: 'PhonesInfo',
        href: '#PhonesInfo',
        title: 'Телефон',
    },
    {
        key: 'MessengerInfo',
        href: '#MessengerInfo',
        title: 'Мессенджеры',
    },
    {
        key: 'ContactInfo',
        href: '#ContactInfo',
        title: 'Электронная почта',
    },
    {
        key: 'SitesInfo',
        href: '#SitesInfo',
        title: 'Сайты',
    },
    {
        key: 'SocialMediaInfo',
        href: '#SocialMediaInfo',
        title: 'Соц. сети',
    },

    {
        key: 'WorkTime',
        href: '#WorkTime',
        title: 'Время работы',
    },
/*
    {
        key: 'PaymentMethods',
        href: '#PaymentMethods',
        title: 'Способы оплаты',
    },
    {
        key: 'Description',
        href: '#Description',
        title: 'Описание',
    },
    {
        key: 'Logo',
        href: '#Logo',
        title: 'Лого',
    },
*/

]