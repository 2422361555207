import React, {useEffect, useState} from 'react';
import cn from "classnames";
import {pluralize, sourcesForGeo} from "../../../../../helpers";
import {Sidebar} from "primereact/sidebar";
import {
    processing_icon,
    okreview_icon
} from "../../../../../helpers/icons";
import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment"

const DataSynchronizationItem = inject('geoStore', 'geoDataStore', 'geoDiffStore')(observer(
    ({geoStore, geoDataStore, geoDiffStore, ad, companyInfo, companyId }) => {

        const [visibleCompareSideBar, setVisibleCompareSideBar] = useState(false);
        const [sources, setSources] = useState([])
        const [diffData, setDiffData] = useState(null)

        let adData = null;

        useEffect(() => {
            setSources(sourcesForGeo())
            adData = parseSourceDiff(ad.type)

            setDiffData(geoDiffStore.mergeAdDataAndMainData(adData.difference.patch, ad.type))
        }, [])

        const findSourceByCode = (code) => {
            return sources.find(source => source.value === code)
        }

        const parseSourceDiff = (source = 'yandex') => {
            return companyInfo.adplatforms.find(ad=> {
                return ad.type === source
            })
        }
        return (
            <>
                {ad.difference.count > 0 ? <div
                        className={cn('content__info', 'warning')}
                        style={{cursor: 'pointer'}}
                        onClick={() => setVisibleCompareSideBar(true)}
                    >
                        <span className={cn('pi', 'pi-times')}></span>
                        {ad.difference.count} {pluralize(ad.difference.count, ['расхождение', 'расхождения', 'расхождений'], false)}
                        <span className={cn('pi', 'pi-arrow-right')}></span>
                    </div> :

                    <div className={cn('content__info', 'success')}>
                        <span className={cn('pi', 'pi-check')}></span>
                        Расхождений нет
                    </div>}

                {diffData !== null && <CompareSideBar
                    visibleCompareSideBar={visibleCompareSideBar}
                    setVisibleCompareSideBar={setVisibleCompareSideBar}
                    findSourceByCode={findSourceByCode}
                    source={ad.type}
                    companyId={companyId}
                    geoData={parseSourceDiff(ad.type)}
                    diffData={diffData}
                />}
            </>
        )
    }
))

const setColorClass = (data) => {
    if(data.patch && data.patch.direction === 'incomming') {
        if(data.patch.action === 'add' || data.patch.action === 'change')
            return 'red'
    }
    else {
        return 'black'
    }
}

const ShowValueString = ({data}) => {
    const [visible, setVisible] = useState(true);

    const getValue = () => {
        if(data.patch) {
            return data.patch.value
        }
        else {
            return data.main
        }
    }

    useEffect(()=>{
        setVisible(data.patch || data.main)
    }, [])

    return (<>
        {visible && <span className={cn(`color__${setColorClass(data)}`)}>{getValue()}</span>}
    </>)
}

const ShowValueStringIco = ({data, icon = ['solid', 'mobile-screen']}) => {
    const [visible, setVisible] = useState(true);
    const getValue = () => {
        if(data.patch) {
            return data.patch.value
        }
        else {
            return data.main
        }
    }

    useEffect(()=>{
        setVisible(data.patch || data.main)
    }, [])

    return (<>
        {visible && <li className={cn(`color__${setColorClass(data)}`)}>
            <span className={cn('phone-list__icon-wrapper', )}>
                <FontAwesomeIcon icon={icon} fixedWidth/></span>
            {getValue()}
        </li>}
    </>)
}

const ShowValueList = ({data, icon = ['solid', 'mobile-screen']}) => {
    const [list, setList] = useState([])


    useEffect(()=>{
        let tmpList = [];

        if(!!data.main) {
            data.main.map(item => {
                tmpList.push({
                    value: item,
                    color: 'black'
                })
            })
        }

        if(!!data.patch && data.patch?.direction === 'incomming') {
            data.patch.value.map(item => {
                tmpList.push({
                    value: item,
                    color: 'red'
                })
            })
        }
        setList(tmpList)

    }, [])

    return (<>
        {list.length > 0 && list.map((item, index) => {
            if(item.value.number !== '') {
                return (
                    <li key={index} className={cn(`color__${item.color}`)}>
                    <span
                        className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                        icon={icon}
                        fixedWidth/></span>{item.value.number ?? item.value}
                    </li>
                )

            }
        })}
    </>)
}

const ShowValueCategory = ({data}) => {
    const [list, setList] = useState([])

    useEffect(() => {
        let tmpList = []

        if(!!data.main) {
            data.main.map(item => {
                tmpList.push({
                    value: item,
                    color: 'black'
                })
            })
        }

        if(!!data.patch && data.patch?.direction === 'incomming') {
            data.patch.value.map(item => {
                tmpList.push({
                    value: item,
                    color: 'red'
                })
            })
        }

        setList(tmpList)

    }, [])


    return (<>
        {list.length > 0 && list.map((item, index) => {
            return (
                <span className={cn('category', `color__${item.color}`)} key={index}>
                    {item.value.id}&nbsp;{item.value.title}
                </span>
            )
        })}
    </>)
}

const WorkTimeBlock = ({data, showMainOnly = false}) => {
    const [workTime, setWorkTime] = useState(null)
    const [colored, setColored] = useState(false)

    const [isNotDiff, setIsNotDiff] = useState(false)
    const [isWork5Week, setIsWork5Week] = useState(false)

    const weekDay = {
        "1": "Пн",
        "2": "Вт",
        "3": "Ср",
        "4": "Чт",
        "5": "Пт",
        "6": "Сб",
        "7": "Вс"
    }

    useEffect(() => {

        if(data.patch && data.patch.direction === 'incomming' && !showMainOnly) {
            setWorkTime(data.patch.value)
            setColored(true)
        }
        else {

            setWorkTime(data.main)
        }

    }, [])

    useEffect(()=>{
        if (workTime !== null) {
            if (workTime.length === 7) {
                const diff = workTime.every((item, index, ar) => {
                    return JSON.stringify(item.work) === JSON.stringify(ar[0].work)
                })
                setIsNotDiff(diff)
            }

            if (workTime.length === 5) {
                const work5Week = workTime.map((item, index, ar) => {
                    if ([1, 2, 3, 4, 5].includes(item.day)) {
                        if (JSON.stringify(item.work) === JSON.stringify(ar[0].work)) {
                            return true
                        }
                    }
                })

                if ([...new Set(work5Week)].length === 1 && [...new Set(work5Week)][0] === true) {
                    setIsWork5Week(true)
                }
            }
        }

    }, [workTime])

    return (<>
            <div className={cn({'color__red': colored})}>
                {workTime !== null && isNotDiff ?

                    <>ежедневно<br/>{workTime[0].work.start}-{workTime[0].work.finish}</>
                    : isWork5Week ?
                    <>
                        Пн-Пт:
                        <br/>{workTime[0].work.start}-{workTime[0].work.finish}
                        <br/>
                        Сб-Вс:
                        <br/> выходной
                    </>
                    : <ul>
                    {workTime?.length > 0 && workTime.map((item, index) => {
                        return (
                            <li key={index}>
                                        <span style={{
                                            'width': '22px',
                                            'display': 'inline-block'
                                        }}>{weekDay[item.day]}:</span>
                                <nobr>{item.work.start}-{item.work.finish}</nobr>
                            </li>
                        )
                    })}
                </ul>

                }
            </div>
    </>)
}

const DataSynchronizationInfo = ({companyId, companyInfo}) => {

    const [sources, setSources] = useState([])

    useEffect(() => {
        setSources(sourcesForGeo())
    }, [])

    const findSourceByCode = (code) => {
        return sources.find(source => source.value === code)
    }

    return (
        <>
            <div className={cn('block', 'synchronization_info')}>
                <div className={cn('block__title')}>
                    <h3>Синхронизация данных</h3>
                </div>
                {(companyInfo.adplatforms.length > 0 && sources.length > 0) &&
                    <div className={cn('block__content')}>
                        {companyInfo.adplatforms.map((ad, key) => {
                            let formatter1 = new Intl.DateTimeFormat("ru");
                            const checkedAt = formatter1.format(new Date(ad.source.checkedAt))

                            const {ico, name} = findSourceByCode(ad.type)

                            return (
                                <div className={cn('content')} key={key}>
                                    <div className={cn('content__title', 'yandex_map')}>
                                        <img src={ico} alt="" width={15} height={15}
                                             title={name}/>
                                        {name}
                                    </div>
                                    <div className={cn('content__status', 'success')}>Опубликован на {ad.title}</div>

                                    <div className={cn('content__info', 'info__title')}>Последняя проверка:</div>
                                    <div className={cn('content__info')}>{checkedAt}</div>
                                    <>
                                        <DataSynchronizationItem ad={ad} companyId={companyId} companyInfo={companyInfo}/>
                                    </>
                                </div>
                            )
                        })}
                    </div>}
            </div>
        </>
    )
}

const CompareSideBar = inject('geoStore', 'geoDataStore')(observer(({
                                                                        visibleCompareSideBar,
                                                                        setVisibleCompareSideBar,
                                                                        findSourceByCode,
                                                                        source,
                                                                        geoStore,
                                                                        geoData,
                                                                        diffData,
                                                                    }) => {

    const [data, setData] = useState(null)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        setData(geoStore.form)
    }, [])

    useEffect(() => {
        if (data) {
            setCategories(getCategoryBySource(source))
        }
    }, [data])

    const getCategoryBySource = (source = 'yandex') => {
        const sp = data.setupPlace.find(sp => {
            return sp.code === 'legalInfoSetup'
        })

        const block = sp.blocks.find(s => s.source === source)
        return block.fields[0].fieldValue || []
    }

    return (

        <Sidebar
            visible={visibleCompareSideBar}
            position="bottom"
            onHide={() => setVisibleCompareSideBar(false)}
            className={cn('side-bar', 'compare-side-bar')}
            blockScroll={true}
            icons={() => (
                <button
                    className={cn('p-link', 'close-text')}
                    onClick={() => setVisibleCompareSideBar(false)}
                >
                    Закрыть
                </button>
            )}
        >
            <div className={cn('compare-side-bar__wrapper')}>
                {data?.title && <div className={cn('compare-side-bar__header')}>
                    <div className={cn('compare-side-bar__title')}>
                        <b>{data.title.value}</b><br/><span
                        style={{fontSize: '12px'}}>{data.address.value.postcode} {data.address.value.city} {data.address.value.street} {data.address.value.building}</span>
                    </div>
                    <div className={cn('compare-side-bar__subtitle')}>
                        <div className={cn('compare-side-bar__subtitle-text')}>
                            {geoData.difference.count} {pluralize(geoData.difference.count, ['расхождение', 'расхождения', 'расхождений'], false)}
                        </div>
                        <div className={cn('compare-side-bar__subtitle-date')}>
                            <img src={processing_icon} width={20} height={15} alt=""/>
                          {moment(geoData.source.checkedAt).format("hh:mm, DD MMMM YYYY")}
                        </div>
                    </div>
                </div>}
                <div className={cn('table', 'data-compare-table', 'company-data-compare-table')}>
                    <div className={cn('table__wrapper')}>
                        <div className={cn('table')}>
                            <div className={cn('table__header')}>
                                <div className={cn('table__row')} style={{"--my-repeat": 8}}>
                                    <div className={cn('table__cell')}>Источник</div>
                                    <div className={cn('table__cell')}>Название</div>
                                    <div className={cn('table__cell')}>Адрес</div>
                                    <div className={cn('table__cell')}>Категории</div>
                                    <div className={cn('table__cell')}>Телефоны, мессенджеры, эл.почта</div>
                                    <div className={cn('table__cell')}>Сайт и соцсети</div>
                                    <div className={cn('table__cell')}>График работы</div>
                                    <div className={cn('table__cell')}>Способ оплаты</div>
                                </div>
                            </div>
                            {diffData !== null && <div className={cn('table__body')}>
                                <div className={cn('table__row')} style={{"--my-repeat": 8}}>
                                    <div className={cn('table__cell', 'cell')}>
                                        <img src={findSourceByCode(source).ico} alt="" width={14} height={14}
                                             title={findSourceByCode(source).name}/>{findSourceByCode(source).name}
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ShowValueString data={diffData.title}/>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>address</div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ShowValueCategory data={diffData.category}/>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ul className={cn('phone-list')}>
                                            {diffData.phones && <ShowValueList data={diffData.phones} icon={['solid', 'mobile-screen']}/>}
                                            {diffData.emails && <ShowValueList data={diffData.emails} icon={['regular', 'envelope']}/>}
                                            {diffData.whatsapp_phone && <ShowValueStringIco data={diffData.whatsapp_phone} icon={['brands', 'whatsapp']}/>}
                                            {diffData.tg_link && <ShowValueStringIco data={diffData.tg_link} icon={['brands', 'telegram']}/>}
                                            {diffData.viber_phone && <ShowValueStringIco data={diffData.viber_phone} icon={['brands', 'viber']}/>}
                                        </ul>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ul className={cn('phone-list')}>
                                            {diffData.websites && <ShowValueList data={diffData.websites} icon={['solid', 'earth-europe']}/>}
                                            {diffData.vk_url && <ShowValueStringIco data={diffData.vk_url} icon={['brands', 'vk']}/>}
                                            {diffData.youtube_link && <ShowValueStringIco data={diffData.youtube_link} icon={['brands', 'youtube']}/>}
                                            {diffData.ok_link && <ShowValueStringIco data={diffData.ok_link} icon={['brands', 'odnoklassniki']}/>}
                                            {diffData.twitter_link && <ShowValueStringIco data={diffData.twitter_link} icon={['brands', 'x-twitter']}/>}
                                        </ul>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <WorkTimeBlock data={diffData['work-time']}/>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>???</div>
                                </div>
                                <div className={cn('table__row')} style={{"--my-repeat": 8}}>
                                    <div className={cn('table__cell', 'cell')}>
                                        <img src={okreview_icon} width={14} height={14} alt=''/>
                                        OK Review
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>{diffData.title.main}</div>
                                    <div className={cn('table__cell', 'cell')}>address</div>
                                    <div className={cn('table__cell', 'cell')}>
                                        {diffData.category.main.length > 0 && diffData.category.main.map((item, index) => {
                                            return (
                                                <span className={'category'} key={index}>
                                                    {item.id}&nbsp;{item.title}
                                                    {index !== categories.length - 1 && ', '}
                                                </span>
                                            )
                                        })}
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ul className={cn('phone-list')}>
                                            {diffData.phones?.main.length > 0 && diffData.phones.main.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span
                                                            className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                            icon={['solid', 'mobile-screen']}
                                                            fixedWidth/></span>{item.number}
                                                    </li>
                                                )
                                            })}

                                            {diffData.emails?.main?.length > 0 && diffData.emails.main.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span
                                                            className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                            icon={['regular', 'envelope']} fixedWidth/></span>{item}
                                                    </li>
                                                )
                                            })}

                                            {diffData.whatsapp_phone?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'whatsapp']}
                                                    fixedWidth/></span>{diffData.whatsapp_phone.main}
                                            </li>}
                                            {diffData.tg_link?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'telegram']}
                                                    fixedWidth/></span>{diffData.tg_link.main}
                                            </li>}
                                            {diffData.viber_phone?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'viber']} fixedWidth/></span>
                                                {diffData.viber_phone.main}
                                            </li>}
                                        </ul>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ul className={cn('phone-list')}>
                                            {diffData.websites?.main?.length > 0 && diffData.websites.main.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span className={cn('phone-list__icon-wrapper')}>
                                                            <FontAwesomeIcon icon={['solid', 'earth-europe']}
                                                                             fixedWidth/></span>
                                                        {item}
                                                    </li>
                                                )
                                            })}
                                            {diffData.vk_url?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'vk']} fixedWidth/></span>{diffData.vk_url.main}
                                            </li>}
                                            {diffData.youtube_link?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'youtube']}
                                                    fixedWidth/></span>{diffData.youtube_link.main}</li>}
                                            {diffData.ok_link?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'odnoklassniki']}
                                                    fixedWidth/></span>{diffData.ok_link.main}</li>}
                                            {diffData.twitter_link?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'x-twitter']}
                                                    fixedWidth/></span>{diffData.twitter_link.main}</li>}
                                        </ul>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <WorkTimeBlock data={diffData['work-time']} showMainOnly={true}/>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        ????
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

        </Sidebar>
    )

}))


export default inject('geoStore')(observer(DataSynchronizationInfo));