import React, {useEffect, useRef} from "react";
import cn from "classnames";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Chart3 = ({data}) => {
    let root = null;
    let container = useRef(null);

    const destroyChart = () => {
        root && root.dispose();
    }

    const initChart = (data = [], totalHeight = 100) => {
        am5.ready(function () {
            root = am5.Root.new("chart3");
            root.locale = am5locales_ru_RU;

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                //paddingLeft: 10,
                //paddingRight: 10,
                layout: root.verticalLayout
            }));

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none"
            }));

            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 10,
                minorGridEnabled: true,
                strokeOpacity: 0.1
            });

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer
            }));

            xRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            })

            xRenderer.labels.template.setAll({
                paddingTop: 5,
                fontSize: 11,
            });

            xAxis.data.setAll(data);

            let paretoAxisRenderer = am5xy.AxisRendererY.new(root, {});

            paretoAxisRenderer.labels.template.setAll({
                fontSize: 11,
                visible: true,
            });

            paretoAxisRenderer.grid.template.setAll({
                visible: true,
                location: 1,
                //minorGridEnabled: true,
            })

            let paretoAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: paretoAxisRenderer,
                //min: 0,
                //max: totalHeight || null,
                //strictMinMax: true
            }));

            //paretoAxisRenderer.grid.template.set("forceHidden", true);
            //paretoAxis.set("numberFormat", "#'%");

            let series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: paretoAxis,
                    valueYField: "percentages",
                    categoryXField: "month",
                    stroke: "#EF8D78",
                    maskBullets: true,
                    fill: "#EF8D78",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}%"
                    })
                })
            );

            series.fills.template.setAll({
                visible: true,
                opacity: 0.2
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Label.new(root, {
                        //text: "{valueY}%",
                        fontSize: 11,
                        centerY: 25,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Circle.new(root, {
                        //tooltipText: "{valueY}%",
                        radius: 3,
                        fill: "#EF8D78",
                        stroke: "#EF8D78",
                    })
                })
            });

            series.data.setAll(data);

            container.current = root;
            series.appear();
            chart.appear(1000, 100);
        });
    }

    useEffect(() => {
        initChart(data.data, (data.total*1.1))

        return () => destroyChart();
    }, [data])


    return (
        <>
            <div className={cn('chart')} >
                <div className={cn('chart__title')}>Конверсия в переход</div>
                <div className={cn('chart__content')} id={'chart3'}></div>
                <div className="chart__legend">&nbsp;</div>
            </div>
        </>
    )
}

export default Chart3;