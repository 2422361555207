import React, {useEffect, useRef} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import cn from "classnames";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import { toNumber } from "@amcharts/amcharts4/.internal/core/utils/Type";

const Block2Chart = ({data = [], index, fillColor = '#8BD8AB', visibleLabels = false, maxHeight = 100}) => {
    let root = null;
    let container = useRef(null);

    const destroyChart = () => {
        root && root.dispose();
    }

    const initChart = (data = [],
                       index,
                       fillColor = '#8BD8AB',
                       visibleLabels = false,
                       maxHeight = 100) => {
        am5.ready(function () {
            root = am5.Root.new(`chart2${index}`);
            root.locale = am5locales_ru_RU;
            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
/*
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 5,
*/

                layout: root.verticalLayout,
            }));

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 10,
                minorGridEnabled: true,
                strokeOpacity: 0
            });

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer
            }));

            xRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            })

            xRenderer.labels.template.setAll({
                visible: visibleLabels,
                paddingTop: 5,
                fontSize: 11,
            });

            xAxis.data.setAll(data);

            let yRenderer = am5xy.AxisRendererY.new(root, {});

            yRenderer.labels.template.setAll({
                fontSize: 11,
                visible: true,
            });

            yRenderer.grid.template.setAll({
                location: 1,
                visible: true,
            });

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
                min: 0,
                max: maxHeight,
            }));

            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "month"
            }));

            series.columns.template.setAll({
                //maxWidth:45,
                //width: 45,
                //tooltipText: "{valueY}",
                tooltipY: 0,
                fill: fillColor,
                strokeOpacity: 0,
                cornerRadiusTL: 0,
                cornerRadiusTR: 0
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        //fill: root.interfaceColors.get("alternativeText"),
                        fontSize: 11,
                        centerY: 22,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            series.data.setAll(data);

            container.current = root;
        });
    }

    useEffect(() => {

        initChart(data.chart,
            index,
            fillColor,
            visibleLabels,
            (maxHeight*1.1))

        return () => destroyChart();
    }, [data]);


    return (
        <>
            <div className={cn('content chart__item')}>
                <div className={cn('item item__ico')}>
                    <img src={data.icon} alt={data.title} width={30} height={30}/>
                </div>
                <div className={cn('item item__digit')}>
                    <span>{data.reviews.toLocaleString('ru-RU')}</span>
                    <span className={cn('color__green', {'color__red': toNumber(data.diff) < 0})}>{data.diff}</span>
                </div>
                <div className={cn('item item__chart')} id={`chart2${index}`}></div>
            </div>
        </>
    )
}

export default Block2Chart;