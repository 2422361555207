import cn from "classnames";
import OkInputText from "../../../../common/OkInputText";
import Setup from "../Setup";
import {TopFormFields} from "../../../../../stores/forms/topForm.store";
import React from "react";
import {inject, observer} from "mobx-react";
import {Dropdown} from "primereact/dropdown";

const StatusBranch = ({geoStore}) => {

    const {form} = geoStore;

    const list = [
        { name: 'Работает', code: 1 },
        { name: 'Временно не работает', code: 2 },
        { name: 'Больше не работает', code: 3 },
    ];

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Статус филиала</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <Dropdown
                                    optionLabel="name"
                                    optionValue="code"
                                    id='status_branch'
                                    name='status_branch'
                                    required={form.status_branch.required}
                                    value={form.status_branch.value}
                                    options={list}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('status_branch', e.target.value)
                                    }}/>
                                <label htmlFor='short_title'>
                                    {form.status_branch.required && <span>*</span>}
                                    {form.status_branch.title}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default inject('geoStore')(observer(StatusBranch));